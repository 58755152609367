import {
    DATA_ATTRIBUTES, STORAGE_KEYS, STORAGE_STATE,
} from '@/hosting/calendly-appointments/calendly-forms-constants';
import { getPublicFormInfo } from '@/hosting/keap-forms-processing/smart-form-api';
import { getItem, setItem } from '@/hosting/storage';

export async function initStorageDataFromInfContactKey(widgetContainer) {
    const calendlyFormSlug = widgetContainer.getAttribute(DATA_ATTRIBUTES.CALENDLY_FORM_SLUG);
    const urlParams = new URLSearchParams(window.location.search);
    const infContactKey = urlParams.get('inf_contact_key');

    if (!calendlyFormSlug || !infContactKey) {
        return;
    }

    try {
        const response = await getPublicFormInfo(calendlyFormSlug, infContactKey);
        const fields = response.form.fields;

        let data = [];

        fields.forEach((field) => {
            if (field.id === STORAGE_KEYS.STANDARD_GIVEN_NAME) {
                data.push([STORAGE_KEYS.STANDARD_GIVEN_NAME, field.defaultValues[0]]);
            }

            if (field.id === STORAGE_KEYS.STANDARD_FAMILY_NAME) {
                data.push([STORAGE_KEYS.STANDARD_FAMILY_NAME, field.defaultValues[0]]);
            }

            if (field.id === STORAGE_KEYS.STANDARD_EMAIL_ADDRESS1) {
                data.push([STORAGE_KEYS.STANDARD_EMAIL_ADDRESS1, field.defaultValues[0]]);
            }
        });

        const formData = getItem(STORAGE_STATE.CALENDLY_STATE) ?? {};

        data.forEach((pair) => {
            formData[pair[0]] = pair[1];
        });

        setItem(STORAGE_STATE.FORM_STATE, formData);

    } catch (error) {
        console.error('Error fetching form pages info with inf contact key:', error);
    }
}
