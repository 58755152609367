export const convertStringToArray = (str) =>  {
    return str ? str.split(',').map(Number) : [];
};


export const convertStringArrayToNumberArray = (arr) => {
    if (arr.length > 1) {
        return  typeof arr[0] !== 'string' ? [] : arr.flatMap((element) => element.split(',').map(Number));
    }

    return arr.length ? arr[0].split(',').map(Number) : [];
};
