import { documentLoaded } from '@/shared/dom-utils';
import { initStorageDataFromInfContactKey } from '@/hosting/calendly-appointments/init-storage-data';
import {
    addCalendlyEventListeners,
} from '@/hosting/calendly-appointments/calendly-events';
import { STORAGE_KEYS, STORAGE_STATE, DATA_ATTRIBUTES } from './calendly-forms-constants';
import { getItem, setItem } from '@/hosting/storage';

export async function processCalendly() {
    await documentLoaded();

    const widgetContainers = document.querySelectorAll('div[id^="calendly-container-"]');

    if (widgetContainers.length === 0) {
        return;
    }

    await initStorageDataFromInfContactKey(widgetContainers[0]);

    const givenNameKeys = ['firstname', 'first_name', 'fn', 'f_n', 'f_name', 'fname'];
    const familyNameKeys = ['lastname', 'last_name', 'ln', 'l_n', 'l_name', 'lname'];
    const emailKeys = ['email', 'em', 'e', '_e'];

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const formData = getItem(STORAGE_STATE.FORM_STATE) ?? {};

    // Looping through the possible keys is required because we do not have specific known key for firstname or lastname from url params
    function findValue(keys) {
        for (const key of keys) {
            for (const [param, value] of urlParams.entries()) {
                if (param.toLowerCase() === key.toLowerCase() || param.toLowerCase() === `utm_${key.toLowerCase()}`) {
                    return value;
                }
            }
        }

        return '';
    }

    function saveToSessionStorage(key, value) {
        const formData = getItem(STORAGE_STATE.CALENDLY_STATE) ?? {};

        formData[key] = value;

        setItem(STORAGE_STATE.CALENDLY_STATE, formData);
    }

    function getNamePart(inputString, part) {
        if (!inputString) {
            return '';
        }
        const nameParts = inputString.split(' ');

        if (part === 'givenName') {
            return nameParts[0];
        } else if (part === 'familyName') {
            return nameParts.length < 2 ? '' : nameParts[nameParts.length - 1];
        } else {
            return '';
        }
    }

    function capitalize(name) {
        return name.replace(/\b\w/g, (char) => char.toUpperCase());
    }

    function loadCalendlyWidget(widgetContainer) {
        const calendlyUrl = widgetContainer.getAttribute(DATA_ATTRIBUTES.CALENDLY_URL);
        const calendlyTagApply = widgetContainer.getAttribute(DATA_ATTRIBUTES.CALENDLY_TAG_APPLY);
        const calendlyFormSlug = widgetContainer.getAttribute(DATA_ATTRIBUTES.CALENDLY_FORM_SLUG);
        const calendlyPixelEvent = widgetContainer.getAttribute(DATA_ATTRIBUTES.CALENDLY_PIXEL_EVENT);
        const calendlyNameUrlParam = widgetContainer.getAttribute(DATA_ATTRIBUTES.CALENDLY_NAME_URL_PARAM);
        const calendlyEmailUrlParam = widgetContainer.getAttribute(DATA_ATTRIBUTES.CALENDLY_EMAIL_URL_PARAM);

        let {
            [STORAGE_KEYS.STANDARD_GIVEN_NAME]: standardGivenName = '',
            [STORAGE_KEYS.STANDARD_FAMILY_NAME]: standardFamilyName = '',
            [STORAGE_KEYS.STANDARD_EMAIL_ADDRESS1]: standardEmail = '',
        } = formData || {};

        if (!standardGivenName) {
        // try to get from specified Calendly name URL param
            standardGivenName = getNamePart(urlParams.get(calendlyNameUrlParam), 'givenName') || findValue(givenNameKeys);

            if (standardGivenName) {
                // store full name if not empty
                saveToSessionStorage(STORAGE_KEYS.STANDARD_GIVEN_NAME, capitalize(standardGivenName));
            }
        }

        if (!standardFamilyName) {
        // try to get from specified Calendly name URL param
            standardFamilyName = getNamePart(urlParams.get(calendlyNameUrlParam), 'familyName') || '' || findValue(familyNameKeys);

            if (standardFamilyName) {
                // store full name if not empty
                saveToSessionStorage(STORAGE_KEYS.STANDARD_FAMILY_NAME, capitalize(standardFamilyName));
            }
        }

        if (!standardEmail) {
        // try to get from specified Calendly email URL param
            standardEmail = urlParams.get(calendlyEmailUrlParam) || findValue(emailKeys);

            if (standardEmail) {
                // store email if not empty
                saveToSessionStorage('standard.emailAddress1', standardEmail);
            }
        }

        saveToSessionStorage(STORAGE_KEYS.STANDARD_TAG, calendlyTagApply ? calendlyTagApply.split(',').map(Number) : []);
        saveToSessionStorage(STORAGE_KEYS.CALENDLY_FORM_SLUG, calendlyFormSlug);

        if (calendlyPixelEvent) {
            saveToSessionStorage(STORAGE_KEYS.CALENDLY_PIXEL_EVENT, calendlyPixelEvent);
        }

        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;

        script.onload = () => {
            window.Calendly.initInlineWidget({
                url: calendlyUrl,
                parentElement: widgetContainer,
                prefill: {
                    name: `${capitalize(standardGivenName)} ${capitalize(standardFamilyName)}`.trim(),
                    email: standardEmail,
                },
            });
        };
        document.head.appendChild(script);
    }

    widgetContainers.forEach((widgetContainer) => {loadCalendlyWidget(widgetContainer);});

    addCalendlyEventListeners();
}
