import { KeapFieldState } from '../keap-form-state';
import { convertStringToArray, convertStringArrayToNumberArray } from './utils';

/**
 * For a single form field container, looks for inner form controls and binds appropriately.  Some controls,
 * like checkbox list, may have multiple form controls that map to a single outer form field.
 *
 * Based on the type of form controls, one of these functions will be used to bind the controls:
 * @see {bindToInput}
 * @see {bindToSelect}
 * @see {bindToCheckboxOrRadio}
 *
 * @param {HTMLElement} element  The outer form-field container
 * @param {Object} fieldInfo Information about this field
 * @param {KeapFormState} formState The form state this field is attached to. Changes to the field data will be
 * bubbled up to the form using this property
 *
 * @return {KeapFieldState}
 */
export function inspectFormControls(element, fieldInfo, formState) {
    const sel = element.querySelectorAll('input, select, textarea');

    for (let i = 0; i < sel.length; i++) {
        const childElement = sel[i];

        switch (childElement.tagName.toLowerCase()) {
        case 'select':
            return bindToSelect(childElement, fieldInfo, formState);
        case 'textarea':
            return bindToInput('textarea', childElement, fieldInfo, formState);

        case 'input': {
            const inputType = childElement.getAttribute('type');

            switch (inputType) {
            case '':
            case null:
            case 'text':
            case 'color':
            case 'date':
            case 'datetime-local':
            case 'email':
            case 'month':
            case 'number':
            case 'search':
            case 'tel':
            case 'time':
            case 'url':
            case 'week':
            case 'hidden':
                return bindToInput(inputType, childElement, fieldInfo, formState);

            case 'checkbox': {
                const name = childElement.getAttribute('name');
                const others = element.querySelectorAll(`input[type="${inputType}"][name="${name}"]`);

                return bindToCheckboxOrRadio(inputType, childElement, others, fieldInfo, formState);
            }

            case 'radio': {
                const name = childElement.getAttribute('name');
                const others = element.querySelectorAll(`input[type="${inputType}"][name="${name}"]`);

                return bindToCheckboxOrRadio(inputType, childElement, others, fieldInfo, formState);
            }
            default:
                console.warn('Can\'t find any input to bind', childElement);
            }
        }
        }
    }
}

function bindToSelect(select, fieldInfo, formState) {
    if (!fieldInfo.fieldName) {
        fieldInfo.fieldName = select.getAttribute('name');
    }

    const checkPlaceholderOptionExist = select.querySelector('.dropdown-placeholder');
    const checkVirtualFieldExist = fieldInfo.fieldName === 'standard.virtualField.select';

    let fieldState = new KeapFieldState({
        self: select,
        onBind: (value) => {
            const options = select.querySelectorAll('option');

            for (let i = 0; i < options.length; i++) {
                const option = options[i];

                if (option.value == value?.toString()) {
                    select.selectedIndex = i;

                    return;
                }
            }
        },
        inputType: 'select',
        formState,
        ...fieldInfo,
    });


    if (!checkPlaceholderOptionExist) {
        window.addEventListener('load', () => {
            fieldState.update(select.value);
        });
    }

    select.addEventListener('change', (evt) =>  checkVirtualFieldExist  ? fieldState.update(convertStringToArray(evt.target.value)) : fieldState.update(evt.target.value));

    return fieldState;
}

function bindToCheckboxOrRadio(inputType, inputElement, childElements, fieldInfo, formState) {
    if (!fieldInfo.fieldName) {
        fieldInfo.fieldName = inputElement.getAttribute('name');
    }

    const options = {};

    let fieldState = new KeapFieldState({
        self: inputElement,
        inputType,
        formState,
        options,
        ...fieldInfo,
        onBind: (value)=> {
            for (let i = 0; i < childElements.length; i++) {
                const child = childElements[i];
                const childValue = child.value;

                if (inputType === 'checkbox') {
                    if (child.checked !== (value.find((option)=> option == childValue) != null)) {
                        // We are using click because it ensures that the events are fired. This is important since we're
                        // doing our own special rendering of checkbox/radio controls
                        child.click();
                    }
                } else {
                    if (childValue == value?.toString()) {
                        // We are using click because it ensures that the events are fired. This is important since we're
                        // doing our own special rendering of checkbox/radio controls
                        child.click();
                    }
                }
            }
        },
    });

    fieldState.update([], false);

    if (inputType === 'checkbox') {
        for (let i = 0; i < childElements.length; i++) {
            const check = childElements[i];

            check.addEventListener('change', () => {
                const parent = check.closest('.checkbox') ?? check;

                check.checked ? parent.classList.add('selected') : parent.classList.remove('selected');
                const newValue = Array.from(childElements)
                    .filter((i) => i.checked)
                    .map((i) => i.value);

                const newValueChange = (fieldInfo.fieldName === 'standard.virtualField.multiselect') ? convertStringArrayToNumberArray(newValue) : newValue;

                fieldState.update(newValueChange);
            });
        }
    } else {
        for (let i = 0; i < childElements.length; i++) {
            childElements[i].addEventListener('change', (evt) => {
                for (let x = 0; x < childElements.length; x++) {
                    const parent = childElements[x].closest('.radio') ?? childElements[x];

                    childElements[x] !== evt.target ? parent.classList.remove('selected') : parent.classList.add('selected');
                }
                fieldInfo.fieldName === 'standard.virtualField.radio' ? fieldState.update(convertStringToArray(evt.target.value)) : fieldState.update(evt.target.value);
            });
        }
    }

    return fieldState;
}

function bindToInput(inputType, input, fieldInfo, formState) {
    if (!fieldInfo.fieldName) {
        fieldInfo.fieldName = input.getAttribute('name');
    }

    const textFieldType = input.getAttribute('data-input-type') ? input.getAttribute('data-input-type') : inputType;

    let fieldState = new KeapFieldState({
        self: input,
        textFieldType,
        inputType,
        formState,
        ...fieldInfo,
        onBind: (value)=> {

            if ((textFieldType === 'date' || textFieldType === 'time' || textFieldType === 'datetime-local') && value) {
                input.type = textFieldType;
            }

            switch (textFieldType) {
            case 'date':
                // Strip the time off the ISO date, otherwise it will fail to bind.  In the future, we may wish to
                // parse the date, and then use input.valueAsDate
                input.value = value.replace(/T.*/g, '');
                break;
            default:
                input.value = value;
                break;
            }

        },
    });

    if (inputType === 'hidden' &&  fieldInfo?.fieldName === 'standard.tag' && fieldInfo.defaultValue) {
        const tagsArray = fieldInfo.defaultValue.split(',').map(function(tagId) {
            return parseInt(tagId, 10);
        });

        fieldState.bind(tagsArray) ;
    }

    input.addEventListener('change', (evt) => fieldState.update(evt.target.value));

    return fieldState;
}
