
export function encodeTrackingEvent(pixelEvent) {
    if (!pixelEvent) {
        return '';
    }
    const { selectValue, customValue } = pixelEvent;

    if (selectValue === 'Other Event Type') {
        return `${customValue}`;
    }

    return `${selectValue}`;
}


export function decodeTrackingEvent(pixelEvent) {
    const trackingEventValue = pixelEvent.replace(/\s+/g, '');

    return { trackingEventValue };
}
